import { en_US_lang, zh_CN_lang } from "./combine";

export const fallbackLng = "en_US";
export const languages = [fallbackLng, "zh_CN"];
export const defaultNS = "translation";
export const cookieName = "b2c_c_lang";

export function getOptions(lng = fallbackLng) {
  // lng = fallbackLng, ns = defaultNS
  return {
    debug: true,
    resources: {
      en_US: {
        translation: en_US_lang,
      },
      zh_CN: {
        // 异步加载 todo
        translation: zh_CN_lang,
      },
      // 添加更多语言
    },
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    interpolation: {
      escapeValue: false, // React 已经安全处理了
    },
    returnNull: false, // 在 i18next 中，returnNull 是一个配置选项，用于控制当翻译键缺失时的返回值。具体来说，当设置了 returnNull: true 时，如果请求的键在翻译资源中不存在，t 函数将返回 null 而不是默认的键名或空字符串。这在某些情况下可以帮助你更好地处理缺失的翻译。
    parseMissingKeyHandler: (key) => {
      // parseMissingKeyHandler 是 i18next 中的一个配置选项，用于处理缺失的键（即翻译文件中未定义的键）。当你调用 t 函数翻译一个字符串时，如果该键在翻译资源中不存在，parseMissingKeyHandler 可以被用来定义如何处理这种情况
      return `fe.${key}`;
    },
    // ns,
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  };
}
